import Vue from 'vue'
import VueRouter from 'vue-router'
import permissions from './permissions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: () => import('@/views/layouts'),
            children: [
                { path: '/', component: () => import('@/views/layouts/admin'),
                    children: [
                        { path: '', name: 'home', beforeEnter: (to, from, next) => next({ name: 'dashboard', replace: true }) },
                        { path: 'dashboard',                  name: 'dashboard',              component: () => import('@/views/dashboard') },
                        { path: 'customer-dashboard/:uuid',   name: 'customer-dashboard',     component: () => import('@/views/customer') },
                    ],
                    beforeEnter: permissions(['is-auth'])
                },
                { path: '/', component: () => import('@/views/layouts/authorization'),
                    children: [
                        { path: 'sign-in',          name: 'sign-in',            component: () => import('@/views/sign-in') },
                        { path: 'forgot-password',  name: 'forgot-password',    component: ()=> import('@/views/forgot-password') },
                    ],
                    beforeEnter: permissions(['!is-auth'], { name: 'dashboard' })
                }
            ]
        }
    ]
})

export default router
