import { extend } from 'vee-validate';

function phoneNumber() {
    extend("phoneNumber", {
        validate(value) {
            // const regex = new RegExp(/^\+?(02\d{8,17}|642\d{7,16})$/);
            const regex = new RegExp(/^\+?(02|642)\d{7,16}$/);
            return regex.test(value);
        },
        message: field => `${field} should start with 02 (from 9 to 18 numeric characters) or 642 and contains (from 10 to 19 numeric characters).`,
    });
}

export default phoneNumber