<template>
    <div id="app">
        <router-view/>

        <app-spinner
            v-if="busy"
            overlay
            fixed
            body
        />
    </div>
</template>

<style>

</style>
<script>
import AppSpinner from "@/components/app-spinner"

import { mapGetters } from "vuex"
export default {
    components: {
        AppSpinner
    },
    computed: {
        ...mapGetters('loader', [
            'busy'
        ])
    }
}
</script>

<style lang="scss">
#app {
    min-height: 100vh;
}
</style>