import axios from 'axios'
import storage from '@/utils/storage'
import store from '@/store'
import router from '@/router'

const KEY_AUTH_DATA = 'user'
const KEY_AUTH_TOKEN = 'token'
const IDENTITY_PROVIDER = process.env.VUE_APP_IDENTITY_PROVIDER

const JWT_EXPIRY_DIALOG = process.env.VUE_APP_JWT_EXPIRY_DIALOG ? parseInt(process.env.VUE_APP_JWT_EXPIRY_DIALOG) || 0 : 0
const jwt_expiry_dialog = JWT_EXPIRY_DIALOG * 60 * 1000

const state = {
    user: null,
    token: null,
    tokenExpiry: 0,
    logoutTimeoutID: null,
    dialogTimeoutID: null,
    isShowJWTExpiryModal: false,
}

const getters = {
    isAuth: state => state.token ? Boolean(state.token) : false,
    token: state => state.token ? state.token : '',
    tokenExpiry: state => state.tokenExpiry ? state.tokenExpiry : 0,
    user: state => state.user,
    fullName: state => state.user ? `${state.user.FirstName} ${state.user.LastName}` : '',
    firstName: state => state.user ? state.user.FirstName : '',
    lastName: state => state.user ? state.user.LastName : '',
    email: state => state.user ? state.user.Email : '',
    mobileNumber: state => state.user ? state.user.MobileNumber : '',
    authID: state => state.user ? state.user.AuthID : null,
    roles: state => state.roles,
    logoutTimeoutID: state => state.logoutTimeoutID,
    dialogTimeoutID: state => state.dialogTimeoutID,
    isShowJWTExpiryModal: state => state.isShowJWTExpiryModal,
}

const mutations = {
    mutSignIn(state, apidata) {

        // apidata = {
        //     ...apidata,
        //     JWTExpiry: Math.round((Date.now() + 5 * 60000) / 1000)
        // }

        state.token = apidata.JWT
        state.tokenExpiry = apidata.JWTExpiry
        state.user = apidata

        storage.set(KEY_AUTH_TOKEN, apidata.JWT, apidata.JWTExpiry)
        storage.set(KEY_AUTH_DATA, apidata, null, true)

        const roles = apidata?.Roles
        if (roles && roles.length > 0) {
            const result = {}
            for (let i = 0; i < roles.length; i++) {
                const role = roles[i]
                const roleName = roles[i]?.RoleName
                if (roleName) {
                    result[roleName] = role
                }
            }
            state.roles = result
        }

        store.dispatch('CheckExpiredJWT')
    },
    mutResetAuthStore(state) {
        state.user = null
        state.token = null
        state.tokenExpiry = 0
        state.roles = {}
        storage.remove(KEY_AUTH_DATA)
        storage.remove(KEY_AUTH_TOKEN)
    },
    setLogoutTimeoutID(state, id) {
        state.logoutTimeoutID = id
    },
    setDialogTimeoutID(state, id) {
        state.dialogTimeoutID = id
    },
    isShowJWTExpiryModal(state, isShow) {
        state.isShowJWTExpiryModal = isShow
    },
}

const actions = {
    AuthCheck({ dispatch, commit, getters }, params) {
        const payload = {
            ...params,
            IdentityProvider: IDENTITY_PROVIDER,
            IncludeRoles: true,
        }

        return axios.post(`/v2/${getters.spid}/auth_check`, payload)
            .then(response => {
                if (response?.apidata) {
                    const roles = response.apidata?.Roles || []
                    if (roles.length == 0) {
                        return Promise.reject(new Error('Missing Authentication Roles'))
                    }
                    commit('mutSignIn', response.apidata)
                    return Promise.resolve(response.apidata)
                }
                return Promise.reject(new Error('Auth failed'))
            })
            .catch(error => {
                const mfaErrorsCode = [412, 428]
                const errorCodeResp = error?.response?.data?.err_code
                if (errorCodeResp && mfaErrorsCode.includes(errorCodeResp)) {
                    return Promise.reject({
                        'mode': 'MFA',
                        payload,
                        error
                    })
                }

                return Promise.reject(error)
            })
    },
    async SignIn({ dispatch, commit, getters }, params) {
        return await dispatch('AuthCheck', params)

        // const payload = {
        //     ...params,
        //     IdentityProvider: IDENTITY_PROVIDER,
        //     IncludeRoles: true,
        // }
        // return axios.post(`/v2/${getters.spid}/auth_check`, payload)
        //     .then(response => {
        //         if (response?.apidata) {
        //             const roles = response.apidata?.Roles || []
        //             if (roles.length == 0) {
        //                 return Promise.reject(new Error('Missing Authentication Roles'))
        //             }
        //             commit('mutSignIn', response.apidata)
        //             return Promise.resolve(response.apidata)
        //         }
        //         return Promise.reject(new Error('Auth failed'))
        //     })
        //     .catch(error => {
        //         const mfaErrorsCode = [412, 428]
        //         const errorCodeResp = error?.response?.data?.err_code
        //         if (errorCodeResp && mfaErrorsCode.includes(errorCodeResp)) {
        //             return Promise.reject({
        //                 'mode': 'MFA',
        //                 payload,
        //                 error
        //             })
        //         }
        //
        //         return Promise.reject(error)
        //     })
    },
    SignOut({dispatch, getters, commit}){
        if (getters.token) {
            // commit('setLoader', true)
            const payload = {
                SPID: getters.spid,
                JWT: getters.token
            }
            return dispatch('api_auth/AuthLogout', payload)
                .then(() => {
                    // dispatch('googleSignOut').catch(()=>{})
                    // dispatch('SignOutByResetStores')
                    return Promise.resolve(true)
                })
                .catch(error => {

                    return Promise.reject(error)
                })
                .finally(() => {
                    dispatch('SignOutByResetStores')
                    // commit('setLoader', false)
                })
        } else {
            dispatch('SignOutByResetStores')
            return Promise.resolve(true)
        }
    },
    LoadAuthInfo({ commit, dispatch, getters }) {
        const authData = storage.get(KEY_AUTH_DATA, true)
        const token = storage.get(KEY_AUTH_TOKEN)

        if (authData && token) {
            commit('mutSignIn', authData)
        }
    },
    async SignOutByResetStores({ dispatch }) {
        await dispatch('ResetAuthStore')

        router.push({ name:'sign-in' })
            .then()
            .catch(() => {})
    },
    ResetAuthStore({ commit }) {
        commit('mutResetAuthStore')
        return Promise.resolve(true)
    },
    CheckExpiredJWT({ getters, dispatch, commit }) {
        if (getters.logoutTimeoutID !== null) {
            clearTimeout(getters.logoutTimeoutID)
        }
        if (getters.dialogTimeoutID !== null) {
            clearTimeout(getters.dialogTimeoutID)
        }

        const now = Date.now()
        const logoutTimeoutDelay = getters.tokenExpiry * 1000 - now
        const logoutTimeoutID = setTimeout(() => dispatch('LogoutJWTExpiry'), logoutTimeoutDelay)
        commit('setLogoutTimeoutID', logoutTimeoutID)

        if (JWT_EXPIRY_DIALOG > 0) {
            const dialogTimeoutDelay = getters.tokenExpiry * 1000 - now - jwt_expiry_dialog
            const dialogTimeoutID = setTimeout(() => dispatch('CheckJWTExpiryModal'), dialogTimeoutDelay)
            commit('setDialogTimeoutID', dialogTimeoutID)
        }
    },
    LogoutJWTExpiry({ getters, dispatch, commit }) {
        const now = Date.now()
        const jwtExpiry = getters.tokenExpiry * 1000

        const timeDifference = now - jwtExpiry

        if (timeDifference >= 0) {
            if (getters.isShowJWTExpiryModal) {
                dispatch('CloseJWTExpiryModal').then(() => {}).catch(() => {})
            }

            dispatch('SignOutByResetStores')
        } else {
            const logoutTimeoutID = setTimeout(() => dispatch('LogoutJWTExpiry'), Math.abs(timeDifference))
            commit('setLogoutTimeoutID', logoutTimeoutID)
        }
    },
    CheckJWTExpiryModal({ getters, dispatch, commit }) {
        const now = Date.now()
        const jwtExpiry = getters.tokenExpiry * 1000

        const timeDifference = now - (jwtExpiry - jwt_expiry_dialog)

        if (timeDifference >= 0) {
            dispatch('ShowJWTExpiryModal').then(() => {}).catch(() => {})
        } else {
            const dialogTimeoutID = setTimeout(() => dispatch('CheckJWTExpiryModal'), Math.abs(timeDifference))
            // console.log('RECURSION checkJWTExpiryModal', timeDifference)
            commit('setDialogTimeoutID', dialogTimeoutID)
        }
    },
    ShowJWTExpiryModal({ commit }) {
        commit('isShowJWTExpiryModal', true)
        return Promise.resolve()
    },
    CloseJWTExpiryModal({ commit }) {
        commit('isShowJWTExpiryModal', false)
        return Promise.resolve()
    },
    RefreshAuthJWT({ dispatch, commit, getters }, jwtArg = null) {
        const jwt = jwtArg || getters.token

        const payload = {
            AuthCode: jwt,
            AuthID: 'jwt',
            IdentityProvider: 'JWT_REFRESH',
            IncludeRoles: true,
        }

        return axios.post(`/v2/${ getters.spid }/auth_check`, payload)
            .then(response => {
                if (response?.apidata) {
                    commit('mutSignIn', response.apidata)
                    dispatch('CloseJWTExpiryModal')

                    return Promise.resolve(response.apidata)
                }
                return Promise.reject(new Error('JWT REFRESH FAILED'))
            })
            .catch(error => Promise.reject(error))
    },
    RefreshAuthJWTByReload({ dispatch }) {
        const token = storage.get(KEY_AUTH_TOKEN)

        if (token) {
            return dispatch('RefreshAuthJWT', token)
                .then(apidata => Promise.resolve(apidata))
                .catch(error => Promise.resolve())
        }

        return Promise.resolve()
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    // namespaced: true
}