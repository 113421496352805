import Vue from 'vue'
import Vuex from 'vuex'

import modulesLoader from './modules'

Vue.use(Vuex)

const SPID = process.env.VUE_APP_SPID
const THEME = process.env.VUE_APP_THEME ? process.env.VUE_APP_THEME : 'vodafone'

const modules = modulesLoader()

export default new Vuex.Store({
    modules,

    state: {
        isLoader: false
    },

    getters: {
        spid: () => parseInt(SPID),
        theme: () => THEME,
        host: () => `${window.location.protocol}//${window.location.host}`,
    },

    mutations: {
        setLoader(state, loader){
            state.isLoader = loader
        }
    },
    actions: {}
})
