<template>
    <div :class="['app-spinner', { overlay }, { radius }, { 'position-fixed': body }]">
        <b-spinner
            variant="primary"
        />
    </div>
</template>

<script>
export default {
    props: {
        overlay: {
            type: Boolean,
            default: false
        },
        radius: {
            type: Boolean,
            default: false
        },
        body: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        if (this.body) {
            document.body.classList.add('busy')
        }
    },
    destroyed() {
        if (this.body) {
            document.body.classList.remove('busy')
        }
    }
}
</script>

<style lang="scss">
.app-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    &.overlay {
        background: $color-overlay;
    }
    &.radius {
        border-radius: $border-radius-bold;
    }
    &.fixed {
        position: fixed;
    }
}
</style>