import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axiosMiddleware from '@/middlewares/axios-middleware'
import axios from 'axios'
Vue.use(axiosMiddleware, axios, store, router)
Vue.prototype.$axios = axios

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import directives from '@/directives'
Vue.use(directives)

import HighchartsVue from "highcharts-vue"
Vue.use(HighchartsVue)

import vSelect from "vue-select"
Vue.component('v-select', vSelect)

// Vue Validator
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
localize('en', en)
// install rules
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
});
import validators from '@/validators'
Vue.use(validators)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

Vue.config.productionTip = false

import stylesCSS from '@/assets/scss/styles.scss'

Vue.config.productionTip = false

store.dispatch('LoadAuthInfo').then(()=>{}).catch(()=>{})

const load = async () => {
    store.dispatch('RefreshAuthJWTByReload')
        .then(() => {
            new Vue({
                stylesCSS,
                router,
                store,
                render: h => h(App)
            }).$mount('#app')
        })
        .catch(() => {
            console.log('Refresh Auth Bt Reload failed')
        })
}

load()
