/**
 * Перехватчик запросов для axios:
 * - добавляет токен авторизации в заголовке X_Auth_Key при отправке каждого запроса
 * - обрабатывает полученнй результат по запросу
 * - разлогинивает юзера при получении 401
 * 
 * @param {*} Vue 
 * @param {*} axios 
 * @param {*} store 
 * @param {*} router 
 */
// import errMessageApi from '@/helpers/errMessageApi'

const axios_config = {
    timeout: 10000,
    maxRedirects: 1,
    withCredentials: false,
}

const X_Auth_Key = 'x-auth-token'

function axiosMiddleware(Vue, axios, store, router) {
    axios.defaults.baseURL = process.env.VUE_APP_API_HOST
    axios.defaults.headers[X_Auth_Key] = null

    axios.interceptors.request.use(
        config => {
            const auth_token = store.getters.token

            if (auth_token) {
                config.headers[X_Auth_Key] = auth_token
            }

            if (config.url.includes('migration')) {
                config.baseURL = process.env.VUE_APP_API_RESELLER_HOST
            }

            const SPID = store.getters.spid
            if (SPID) {
                config.url = config.url.replace(/:spid(\/|\?|$)/gi, `${ SPID }$1`)
                if (config?.params && !config.params?.SPID) {
                    config.params.SPID = SPID
                }
                if (config?.data && typeof config.data !== 'string' && !config.data?.SPID) {
                    config.data.SPID = SPID
                }
            }

            return {...axios_config, ...config}
        },
        error => Promise.reject(error)
    )

    axios.interceptors.response.use(
        // обрабатываем результат
        // process the result
        response => {
            if (response.data?.err_message) {
                return Promise.reject(response.data)
            }

            response.result = response.data
            response.apidata = response.data
            response.errorStatus200 = response.data

            // Иногда приходит статус 200 но в респонсе ошибка
            if (response.errorStatus200 && response.errorStatus200.err_code && response.errorStatus200.err_message) {
                switch (response.errorStatus200.err_code) {
                    case 401:
                        // errMessageApi(null, 'Sorry, we were unable to log you in with those credentials')
                        break

                    default:
                        // errMessageApi(null, response.errorStatus200.err_message)
                }
            }

            if (response.headers && response.headers['x-auth-token'] && response.headers['x-auth-token-expiry']) {
                // If API returns a new X-Auth-Token, then we must replace our existing one
                store.dispatch('`setJWT`', response.headers['x-auth-token'], response.headers['x-auth-token-expiry'])
            }
            return Promise.resolve(response)
        },
        
        // разлогиниваем юзера
        // log off user
        error => {
            if (error.response) {
                switch (error.response.status) {
                    // 401: Unauthorized
                    case 401: {
                        if (error.response?.data?.err_field === 'X-Auth-Token') {
                            store.dispatch('signOutByResetStores')
                        }
                        return Promise.reject(error)

                        // if (error.response.data.err_number === 1002) {
                        //     /**
                        //      * err_number 1002
                        //      * err_message Missing authentication role
                        //      */
                        //
                        //     /**
                        //      * Иногда API возвращает 401 статус-код (Неавторизовано), вместо 403 (Запрещено)
                        //      * Например, если у пользователя недостаточно прав...
                        //      * Так как проблема не в сроке жизни токена, а в правах...
                        //      * Мы попадаем в бесконечный цикл: успешное обновление токена => запрос с 401, успешное обновление токена => запрос с 401, ...
                        //      */
                        //     console.log('Error status 401 - Error number 1002')
                        //     return Promise.reject(error)
                        // }
                    }

                    // 403: Forbidden
                    case 403: {
                        store.dispatch('SignOutByResetStores')
                        return Promise.reject({
                            message: '403: Forbidden',
                            details: error
                        })
                    }

                    // Иногда возвращается 404 ошибка но с респонсом
                    case 404: {
                        if (typeof(error.response.data) === 'string' || error.response.data?.err_message || error.response.data?.error) {
                            return Promise.reject(error)
                        }
                        return Promise.resolve(error.response)
                    }

                    default: {
                        return Promise.reject(error)
                    }
                }
            } else {
                return Promise.reject(error)
            }
        }
    )
}

export default axiosMiddleware
