const state = {
    busy: false
}

const getters = {
    busy: state => state.busy
}

const mutations = {
    mutBusy(state, busy) {
        state.busy = busy
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}