import axios from 'axios'
import downloadBlob from '@/utils/downloadBlob'

const RESELLER_API_HOST = process.env.VUE_APP_RESELLER_API_HOST

const state = {
    customer: null,
    state: {
        id: null,
        name: null,
    }
}

const getters = {
    customer: state => state.customer,
    customerFullName: state => state.customer ? `${state.customer?.FirstName} ${state.customer?.LastName}` : '',
    stateName: state => state.state?.name,
    stateID: state => state.state?.id,
}

const mutations = {
    SetCustomer(state, customer) {
        state.customer = customer
    },
    SetState(state, value) {
        state.state = value
    },
    SerStateName(state, name) {
        state.name = name
    },
    SerStateID(state, id) {
        state.id = id
    },
    ResetState(state) {
        state.state.name = null
        state.state.id = null
    }
}

const actions = {
    MeasureProgress({}, req) {
        return axios.get(`${RESELLER_API_HOST}/migration/v1/progress`, { params: req })
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    SearchAccounts({}, req) {
        return axios.get(`${RESELLER_API_HOST}/migration/v1/account`, { params: req })
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    DownloadReport() {
        return axios.get(`${RESELLER_API_HOST}/migration/v1/report`, { responseType: 'blob' })
            .then(({ result: blob }) => {
                return downloadBlob(`Report of all migrations.csv`, blob)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}